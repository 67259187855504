.streamContent {
    padding-top: 0px;
}

.pageTitle {
    font-family: 'Dolce Vita Heavy Bold';
    color: black;
    height: 48px;
    width: 100%;
    text-align: center;
    border: none !important;
    border-radius: 0px !important;
    position: fixed;
    top: 0;
}

.feedContent {
    overflow: scroll;
    overflow-x: hidden;
    height: 95vh;
    padding-top: 50px;
    padding-bottom: 30px;
}

.searchContainer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.searchInput {
    max-width: 400px;
    background: none;
    border: none;
    padding-left: 15px;
    font-size: 20pt;
    color: black;
}

.searchInput::-moz-selection,
.searchInput::selection {
    background: none;
    border: none;
}

.searchInput::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #ffffff66;
    /* Firefox */
}

.searchInput::-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #ffffff66;
}

.searchInput::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #ffffff66;
}

.card {
    background: #ffffff55;
    margin: 10px 15px 5px 15px;
    border-radius: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
    transition: 0.3s;
    display: flex;
    flex-direction: column;
}

.card:hover,
.card::selection {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4);
    -webkit-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4);
    -moz-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4);
}

.cardText {
    padding-top: 5px;
    padding-right: 10px;
    font-size: 10pt;
}

.cardImage {
    object-fit: contain;
    max-width: 100%;
    background: #ffffff77;
    border-radius: 0 0 10px 10px;
    max-height: 200px;
}

/* 
#002f4b,#dc4225 
Convert HEX to RGBA - http://hex2rgba.devoth.com/
*/
.cardImage:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: inline-block;
    background: -moz-linear-gradient(top, rgba(0, 47, 75, 0.5) 0%, rgba(220, 66, 37, 0.5) 100%);
    /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(220, 66, 37, 0.5)), color-stop(100%, rgba(0, 47, 75, 0.5)));
    /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top, rgba(0, 47, 75, 0.5) 0%, rgba(220, 66, 37, 0.5) 100%);
    /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top, rgba(0, 47, 75, 0.5) 0%, rgba(220, 66, 37, 0.5) 100%);
    /* Opera 11.10+ */
    background: -ms-linear-gradient(top, rgba(0, 47, 75, 0.5) 0%, rgba(220, 66, 37, 0.5) 100%);
    /* IE10+ */
    background: linear-gradient(to bottom, rgba(0, 47, 75, 0.5) 0%, rgba(220, 66, 37, 0.5) 100%);
    /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#002f4b', endColorstr='#00000000', GradientType=0);
    /* IE6-9 */
}

.cardContent {
    display: flex;
    padding: 15px 15px 5px 15px;
}

.cardProfileImage {
    height: 100%;
}

.profileImage {
    width: 40px;
    height: 40px;
    margin: 5px;
    border-radius: 32px;
}

.contentContainer {
    padding: 0px 5px 5px 10px;
    font-size: 10pt;
    width: 100%;
}

.displayName {
    font-weight: bold;
    max-width: 150px;
    font-size: 11pt;
}

.username {
    font-size: 11pt;
    margin: 5px;
    color: #00000088;
}

.date {
    color: #00000066;
    text-align: right;
    padding-top: 5px;
    font-size: 9pt;
    width: 100%;
}

.link {
    cursor: pointer;
}

.version {
    color: white;
    font-size: 7pt;
    position: fixed;
    right: 0px;
    padding-right: 6px;
    padding-top: 4px;
}