@import url(https://fonts.googleapis.com/css?family=Manrope&display=swap);
html,
body,
#root,
.app {
  margin: 0;
  height: 100%;
}

body {
  font-family: "Manrope", Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0;
}

#root {
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.StreamPage_streamContent__294Bv {
    padding-top: 0px;
}

.StreamPage_pageTitle__1tmnm {
    font-family: 'Dolce Vita Heavy Bold';
    color: black;
    height: 48px;
    width: 100%;
    text-align: center;
    border: none !important;
    border-radius: 0px !important;
    position: fixed;
    top: 0;
}

.StreamPage_feedContent__2rkV9 {
    overflow: scroll;
    overflow-x: hidden;
    height: 95vh;
    padding-top: 50px;
    padding-bottom: 30px;
}

.StreamPage_searchContainer__Wg-Ou {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
}

.StreamPage_searchInput__eijss {
    max-width: 400px;
    background: none;
    border: none;
    padding-left: 15px;
    font-size: 20pt;
    color: black;
}

.StreamPage_searchInput__eijss::-moz-selection,
.StreamPage_searchInput__eijss::selection {
    background: none;
    border: none;
}

.StreamPage_searchInput__eijss::-webkit-input-placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #ffffff66;
    /* Firefox */
}

.StreamPage_searchInput__eijss:-ms-input-placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #ffffff66;
    /* Firefox */
}

.StreamPage_searchInput__eijss::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #ffffff66;
    /* Firefox */
}

.StreamPage_searchInput__eijss::-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #ffffff66;
}

.StreamPage_searchInput__eijss::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #ffffff66;
}

.StreamPage_card__19vOk {
    background: #ffffff55;
    margin: 10px 15px 5px 15px;
    border-radius: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
    transition: 0.3s;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
}

.StreamPage_card__19vOk:hover,
.StreamPage_card__19vOk::selection {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4);
    -webkit-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4);
    -moz-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4);
}

.StreamPage_cardText__WIDEI {
    padding-top: 5px;
    padding-right: 10px;
    font-size: 10pt;
}

.StreamPage_cardImage__34BWK {
    -o-object-fit: contain;
       object-fit: contain;
    max-width: 100%;
    background: #ffffff77;
    border-radius: 0 0 10px 10px;
    max-height: 200px;
}

/* 
#002f4b,#dc4225 
Convert HEX to RGBA - http://hex2rgba.devoth.com/
*/
.StreamPage_cardImage__34BWK:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: inline-block;
    /* FF3.6+ */
    /* Chrome,Safari4+ */
    /* Chrome10+,Safari5.1+ */
    /* Opera 11.10+ */
    /* IE10+ */
    background: linear-gradient(to bottom, rgba(0, 47, 75, 0.5) 0%, rgba(220, 66, 37, 0.5) 100%);
    /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#002f4b', endColorstr='#00000000', GradientType=0);
    /* IE6-9 */
}

.StreamPage_cardContent__1qysC {
    display: -webkit-flex;
    display: flex;
    padding: 15px 15px 5px 15px;
}

.StreamPage_cardProfileImage__2x80N {
    height: 100%;
}

.StreamPage_profileImage__1xRZk {
    width: 40px;
    height: 40px;
    margin: 5px;
    border-radius: 32px;
}

.StreamPage_contentContainer__2_Qh8 {
    padding: 0px 5px 5px 10px;
    font-size: 10pt;
    width: 100%;
}

.StreamPage_displayName__konxx {
    font-weight: bold;
    max-width: 150px;
    font-size: 11pt;
}

.StreamPage_username__3ZtvL {
    font-size: 11pt;
    margin: 5px;
    color: #00000088;
}

.StreamPage_date__2iMld {
    color: #00000066;
    text-align: right;
    padding-top: 5px;
    font-size: 9pt;
    width: 100%;
}

.StreamPage_link__2Avh_ {
    cursor: pointer;
}

.StreamPage_version__OOxsu {
    color: white;
    font-size: 7pt;
    position: fixed;
    right: 0px;
    padding-right: 6px;
    padding-top: 4px;
}
.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

a,
a::selection,
a:hover {
  background-color: inherit;
  color: inherit;
  text-decoration: inherit;
}
